@import url(https://fonts.googleapis.com/css2?family=Montserrat&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Tinos);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


body {
  background: #fafcfd;
  font-family: 'Roboto', sans-serif;
}

.Section {
  background: white;
  padding: 10px;
  border-radius: 10px;
  margin-top:20px;
  margin-bottom:20px;
  border: 1px solid #e4e3e3
}

.Section .table td, .table th {
  border-top: none;
}

.Section .NoData {
  margin:auto;
  text-align:center;
  padding:20px
}

.MenuIcon {
  font-size:90px;
  padding:20px;
  color:#166fd0;
}

.PlanIcon {
  border: 1px solid transparent;  
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.PlanIcon.Selected {
  border: 1px solid #ced4da;  
}

.NavBar {
  background:#166fd0;
  font-family: 'Open Sans', sans-serif;
  height:70px;
  letter-spacing:0.1px;
}

.LoginLogo {
  margin:auto;
  width:150px;
}

.Login {
  text-align:center;
  
}

.IconButton {
  cursor: pointer;
  padding: 1px;
}

.Bold {
  font-weight: bold;
}

.LogoutText {
  color: white;
  margin: 5px;
  display: none;
  cursor:pointer;
}

@media (min-width: 768px) { 
  .LogoutText {
    display: initial;
  }
}


.PlanCardDetail {
  font-size: 11pt;
}

.PlanCardDetail ul {
    list-style: none;
    padding:0;
}

.PlanCardDetail ul li {
  margin:10px
}

.BoldBlue {
  font-weight: bold;
  color: #166fd0
}

.PlanCard {
  border: 1px solid #3596ff;  
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
  text-align: center;
}

.PlanCard.Selected {
  /* background: #166fd0; */
  background: #3596ff;
  color: white;
}

.PlanCard.Selected .BoldBlue {
  color:white;
}

.PlanCard.Selected .MenuIcon {
  color: white;
}

.PlanCardButton {
  border: 1px solid #3596ff;  
  color: #3596ff; 
}

.PlanCardButton:hover {
  background: transparent;
  color:#3596ff;
  border: 1px solid #3596ff;  
}

.PlanCard.Selected .PlanCardButton {
  border: 1px solid white;  
  color: white; 
}

.CarouselIndicator {
  width: 20px;
  margin: 0 10px;
  height: 4px;
  background: black;
}

.CarouselIndicator.Selected {
  background: #3596ff
}

.PacksTable thead th {
  padding: 8px;
}

#LogInContainer {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.flex-left {
  width: 50%;
}

.flex-right {
  width: 50%;
  background-color: #166fd0;
}

.scrollable-component {
  width: 100vh;
  height: 100vh;
  background-color: #166fd0;
  position:absolute;
  overflow-y: auto;
}

.side-text-big {
  color:white;
  font-size: 54px;
  font-family: Tinos;
  line-height: .7;
}

.side-text-small {
  color:white;
  font-size: 18px;
  font-family: Montserrat;
}

.side-text {
  margin-left: 5%;
  margin-top: 35%;
}
